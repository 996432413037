<template>
    <main class="page-content" role="main">
        <div class="container-fluid">
            <div class="form-card">
                <div class="row justify-center">
                    <div class="col-md-12 col-lg-12 col-xl-6">
                        <panel id="panel-1">
                            <template v-slot:header>
                                <h2>
                                    <span class="icon-stack fs-xxl mr-2">
                                        <i
                                            class="base base-7 icon-stack-4x opacity-100 color-primary-700"
                                        ></i>
                                        <i
                                            class="fas fa-building icon-stack-2x opacity-100 color-white"
                                        ></i>
                                    </span>
                                    {{ title }}
                                </h2>
                            </template>
                            <div class="panel-container collapse show" style="">
                                <div class="panel-content">
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="user-name"
                                                    >{{
                                                        $t('companies.name')
                                                    }}</label
                                                >
                                                <input
                                                    id="user-name"
                                                    v-model="user.name"
                                                    class="form-control"
                                                    type="text"
                                                />
                                                <p
                                                    v-if="error.name"
                                                    class="help is-danger font-bold text-center"
                                                >
                                                    {{
                                                        $t(
                                                            `error.${error.name}`
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="user-job-title"
                                                    >{{
                                                        $t('companies.function')
                                                    }}</label
                                                >
                                                <input
                                                    id="user-job-title"
                                                    v-model="user.job_title"
                                                    class="form-control"
                                                    type="text"
                                                />
                                                <p
                                                    v-if="error.job_title"
                                                    class="help is-danger font-bold text-center"
                                                >
                                                    {{
                                                        $t(
                                                            `error.${error.job_title}`
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="user-email"
                                                    >{{
                                                        $t('companies.email')
                                                    }}</label
                                                >
                                                <input
                                                    id="user-email"
                                                    v-model="user.email"
                                                    class="form-control"
                                                    type="text"
                                                />
                                                <p
                                                    v-if="error.email"
                                                    class="help is-danger font-bold text-center"
                                                >
                                                    {{
                                                        $t(
                                                            `error.validation.mail_valid`
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="user-locale"
                                                    >{{
                                                        $t('companies.language')
                                                    }}</label
                                                >
                                                <input
                                                    id="user-locale"
                                                    v-model="user.locale"
                                                    class="form-control"
                                                    type="text"
                                                />
                                                <p
                                                    v-if="error.locale"
                                                    class="help is-danger font-bold text-center"
                                                >
                                                    {{
                                                        $t(
                                                            `error.${error.locale}`
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="user-password"
                                                    >{{
                                                        $t(
                                                            'companies.settings.sending_mails.password'
                                                        )
                                                    }}</label
                                                >
                                                <input
                                                    id="user-password"
                                                    v-model="user.password"
                                                    class="form-control"
                                                    :placeholder="
                                                        this.$route.params
                                                            .userId
                                                            ? $t(
                                                                  'placeholder.newPassword'
                                                              )
                                                            : ''
                                                    "
                                                    type="password"
                                                />
                                                <p
                                                    v-if="error.password"
                                                    class="help is-danger font-bold text-center"
                                                >
                                                    {{
                                                        $t(
                                                            `error.${error.password}`
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </panel>
                        <div v-if="!saved" class="d-flex justify-center">
                            <button
                                :disabled="canCreate()"
                                class="btn btn-primary"
                                href="#"
                                @click.prevent="doCreate"
                            >
                                {{ submitButtonText }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Panel from '@/components/UI/Panel'

export default {
    name: 'UserBuilder',
    components: {
        Panel
    },
    data() {
        let userId = this.$route.params.userId
        const title = userId
            ? this.$t('companies.user.edit')
            : this.$t('companies.user.create')
        const submitButtonText = userId
            ? this.$t('companies.user.edit_user')
            : this.$t('companies.user.create_user')
        return {
            loading: false,
            saved: false,
            title,
            submitButtonText,
            error: {}
        }
    },
    computed: {
        ...mapState('company', {
            user: (state) => state.newUser
        })
    },
    created() {
        const userId = this.$route.params.userId
        if (userId) {
            this.loading = true
            this.editUser(userId)
                .catch((e) => {
                    this.$store.dispatch('dispatchError', e)
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    methods: {
        ...mapActions('company', {
            createUser: 'createUser',
            updateUser: 'updateUser',
            editUser: 'editUser'
        }),
        doCreate: async function () {
            this.loading = true
            const userId = this.$route.params.userId
            let request
            if (userId) {
                request = this.updateUser(this.user)
            } else {
                request = this.createUser(this.user)
            }
            request
                .then(() => {
                    if (!userId) {
                        this.$store.commit('company/setNewUser', {
                            password: null,
                            name: null,
                            job_title: null,
                            email: null,
                            locale: null
                        })
                    }
                    this.error = {}
                    this.showSuccessMessage()
                })
                .catch((e) => (this.error = e))
                .finally(() => {
                    this.loading = false
                })
        },
        showSuccessMessage: function () {
            this.$store.dispatch('toast', {
                type: 'success',
                title: this.$t('success.title'),
                message: this.$t('success.message')
            })
        },
        showErrorMessage: function (errorMessage) {
            this.$store.dispatch('toast', {
                type: 'error',
                title: this.$t('error.title'),
                message:
                    errorMessage && errorMessage.length > 0
                        ? errorMessage
                        : this.$t('error.validation.retry')
            })
        },
        canCreate: function () {
            return !!this.loading
        }
    },
    beforeDestroy() {
        this.$store.commit('company/setNewUser', {
            id: null,
            name: null,
            job_title: null,
            email: null,
            locale: null
        })
    }
}
</script>
